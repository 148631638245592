.opc {

	&-wrapper {

		.fieldset {
			border: 0;
			padding: 0;
		}

		.address {
			@include flex-grid-row();
		}

		div[name="shippingAddress.firstname"],
		div[name="shippingAddress.lastname"],
		div[name="shippingAddress.postcode"],
		div[name="shippingAddress.city"] {

			@include flex-grid-column(12);

			@include breakpoint(large) {
				@include flex-grid-column(6);
			}
		}

		div[name="shippingAddress.company"],
		div[name="shippingAddress.region_id"],
		div[name="shippingAddress.country_id"],
		div[name="shippingAddress.telephone"] {

			@include flex-grid-column(12);

		}

		div[name="shippingAddress.region"],
		input[name="street[2]"] {
			@include flex-grid-column(12);
		}

		.street {
			@include flex-grid-column(12);
		}

		div[name="billingAddresscashondelivery.company"] {
			@include flex-grid-column(12);
		}

		.methods-shipping {
			@include flex-grid-row();

			.actions-toolbar {
				@include flex-grid-column(12);
				@include flex-align(right);

				@include breakpoint(medium down) {
					padding-left:0;
					padding-right: 0;
				}

				.primary {
					@include flex;

					@include breakpoint(medium) {
						@include flex-align($x: right);
					}
				}

				.continue {
					@include flex;
					@include flex-align($x: center);
					@include buttonStyle($theme-checkout-next-button-style);
					padding-left: rem-calc(10px);
					padding-right: rem-calc(10px);
					height: rem-calc($input-height);
					width: 100%;
					min-width: rem-calc(180);
					justify-content: center;

					@include breakpoint(medium) {
						width: auto;
					}
				}
			}
		}

		.checkout-shipping-address {

			.step-content {
				@include flex-grid-column(12);
                padding-left: 0px !important;
			}
		}

		.shipping-address {

			&-items {
				@include flex-grid-row();
				padding: $global-margin 0;
			}

			&-item {
				@include flex-grid-column(12);
				padding-bottom: rem-calc(12);
				padding-top: rem-calc(12);

				@include breakpoint(medium) {
					@include flex-grid-column(6);
				}

				&.selected-item {
					border: 1px solid $theme-tertiary-color;
					box-shadow: inset 0px 0px 0px 1px $theme-tertiary-color;
				}
			}
		}

		.step-title {
			@include flex-grid-column(12);
            padding-left: 0px !important;
			font-size: rem-calc(20);
			font-weight: $global-weight-semibold;
		}
	}

	.billing-address-form {
		div[name$=".firstname"],
		div[name$=".lastname"],
		div[name$=".city"],
		div[name$=".region_id"],
		div[name$=".region"],
		div[name$=".postcode"],
		div[name$=".country_id"],
		div[name$=".telephone"] {
			@include flex-grid-column(12);
		}

		div[name$=".company"] {
			@include element-invisible;
		}
	}

	&-summary-wrapper {

		.action-close {
			display: none;
		}

		.minicart-items-wrapper {
			height: auto !important;
		}

		.totals {

			[data-tooltip] {
				@include font(14, 20, $global-weight-semibold);
				align-items: center;
				background: $theme-checkout-flexpay-indicator;
				border-radius: rem-calc(20);
				color: $white;
				display: inline-block;
				display: inline-flex;
				height: rem-calc(20);
				justify-content: center;
				text-align: center;
				width: rem-calc(20);
				margin-left: rem-calc(0);
			}

			tbody {
				border: 0px;
				background: transparent;
				border-bottom: 1px solid #e3e3e3;
			}

			.mark,
			.amount {
				padding: rem-calc(10px);
				.price {
					color: $theme-price-color;
				}

				strong, span {
					font-weight: inherit;
					display: inline-block;
					vertical-align: middle;
				}
			}

			.mark,
			.amount {

				@include breakpoint(large) {
					@include font(16, 22, $global-weight-semibold);
				}

				@include breakpoint(medium down) {
					@include font(14, 14, $global-weight-semibold);
				}

			}

			.mark {
				text-align: left;
				padding-left: 0;
			}

			.amount {
				text-align: right;
				padding-right: 0;
				padding-left: 0;
				white-space: nowrap;
			}
		}

		.title {
			@include flex();
			@include flex-align($x: justify);
			padding-bottom: rem-calc(19);
			margin-bottom: rem-calc(27);
			border-bottom: 1px solid #e3e3e3;
		}

		.minicart-items {
			margin-bottom: 0;

			.product-item {
				margin-bottom: rem-calc(25px);

				&:last-child {
					margin-bottom: 0;
				}

				&:hover {
					.product-item-details {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			.product {
				@include flex();
				border-bottom: 1px solid #e3e3e3;
				padding: rem-calc(0 0 9 0);

				&-image-container {
					margin-right: rem-calc(25);
					height: rem-calc(80px)!important;
					width: rem-calc(80px)!important;
					box-sizing: border-box;
					display: block;
					flex-shrink: 0;
					background-color: rgba($theme-primary-color, 0.05);

					img {
						font-size: rem-calc(8px);
					}
				}

				&-item-name {
					@include flex-align($x: left);
					@include font(15, 17, $global-weight-skinny);
					margin-bottom: rem-calc(10);
					color: $theme-primary-font-color;
					min-height: initial;
					text-align: left;
					height: auto;
					margin-top: 0;
				}

				&-item-details {
					height: auto;
					padding-top: rem-calc(7);
				}

				.term-condition-link {
					@include font(13, 16, $global-weight-skinny);
					color: $theme-primary-font-color;
					display: block;
					text-decoration: underline;
					margin-bottom: rem-calc(10);
				}

				.details-qty,
				.details-qty .label {
					@include font(13, 16, $global-weight-skinny);
					color: $theme-primary-font-color;
					margin-bottom: rem-calc(18);
				}

				.cart-price {
					color: $theme-price-color;
					@include font(16, 19, $global-weight-skinny);
					margin:0;
				}
			}
		}

		.table-totals {
			margin-bottom: 0;
			margin-top: rem-calc(5px);

			.table-caption {
				display: none;
			}

			tbody {
				background: transparent;
				border: none;
			}

			tr .amount {
				white-space: normal;
			}

			.totals.discount {

				.coupon {
					display: none;
				}

				.title,
				.price {
					@include font(14, 14, $global-weight-skinny);
					margin: 0;
					padding: 0;
					border: none;
					letter-spacing: 0px;
				}
			}
		}

		.items-in-cart {

			.actions-toolbar {

				.viewcart {
					@include button-style-1;
					width: 100%;
					margin: rem-calc(10 0);
				}
			}
		}
	}

	&-block-shipping-information {
		@include flex-grid-column(12, 0);

		.shipping-information-title {
			font-size: rem-calc(20);
			font-family: $theme-font-family;
			font-weight: $global-weight-bold;
			line-height: rem-calc(27);
			letter-spacing: rem-calc(1.07);

			.action-edit {
				font-size: rem-calc(12);
				font-weight: $global-weight-normal;
			}
		}
	}

	&-payment {

		.step-title {
			@include element-invisible;
			display: none;
		}

		._active {

			.payment-method-title {
				@include element-invisible;
				display: none;
			}
		}
	}
}
