.header-navigation-container {
	display: block !important;
}

.navigation
.megamenu.level-top-fullwidth
.level0.fullwidth
.fullwidth-wrapper
.fullwidth-wrapper-inner {
	@include flex-grid-row();
	flex-wrap: nowrap;
}

.navigation {
	@include flex;
	@include flex-align($x: center);
	font-weight: $global-weight-bold;
	height: inherit;
	left: auto;
	overflow: inherit;
	padding: 0;
	border-top: 1px solid $light-gray;
	border-bottom: 1px solid $light-gray;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 3;
	box-shadow: $theme-navigation-shadow;
	@include breakpoint(xlarge) {
		background: $theme-header-background;
	}

	> ul {
		@include flex-grid-row();
		margin-top: 0;
		margin-bottom: 0;
		width: 100%;

		@include internet-explorer {
			margin: 0;
		}
	}

	.level0 {
		.submenu {
			background: $theme-navigation-background;
			border: none;
			display: none;
			font-weight: $global-weight-normal;
			left: 0;
			margin-top: 11px;
			margin: 0 !important;
			max-width: 100%;
			min-width: 230px;
			padding: rem-calc(0);
			position: absolute;
			width: 100%;
			z-index: 1;

			@include breakpoint(medium down) {
				background: $white;
			}

			> .fullwidth-wrapper {
				padding-top: rem-calc(15px);
			}

			.columns-group {
				margin-left: 0;

				.submenu {
					.level2 {
						padding: 0;
					}

					a {
						&:before {
							@include flex;
							@include flex-center;
							@include ird-icon($ird-icon-chevron-link, 10px);
							// submenu `>` arrow font color
							color: $theme-primary-color;
							height: rem-calc(10px);
							margin-right: rem-calc(10px);
							transition: all 0.15s linear;
							width: rem-calc(7px);
							position: absolute;
							left: 0;

							@include breakpoint(small only) {
								margin-top: rem-calc(2px);
							}
						}

						&:hover {
							background: transparent;

							&:before {
								left: rem-calc(8px);
							}
						}
					}
				}
			}

			&.sectioned {
				@include flex-grid-row($size: 100%);
			}
		}
	}
}

.navigation:empty {
	display: none;
}

.navigation li.level0 {
	border-top: none;
}
.navigation .level0 {
	margin: 0 10px 0 0;
	display: inline-block;
	position: relative;
	width: 100%;

	@include breakpoint(xlarge) {
		width: auto;
	}
}

.level3 {
	display: none;
}

.navigation .level0:last-child {
	margin-right: 0;
	padding-right: 0;
}
.navigation .level0 > .level-top {
	text-transform: uppercase;
	color: $theme-navigation-color;
	line-height: rem-calc(24);
	padding: 0 12px;
	text-decoration: none;
	box-sizing: border-box;
	position: relative;
	display: inline-block;

	@include breakpoint(xlarge) {
		color: $theme-navigation-color;
		line-height: rem-calc(46);
	}
}
.navigation .level0 > .level-top:hover,
.navigation .level0 > .level-top.ui-state-focus {
	color: $theme-navigation-color;
	text-decoration: none;
}
.navigation .level0.active > .level-top,
.navigation .level0.has-active > .level-top {
	text-transform: uppercase;
	border-color: #ff5501;
	border-style: solid;
	border-width: 0 0 3px;
	color: $theme-navigation-color;
	text-decoration: none;
	display: inline-block;
}
.navigation .level0.parent:hover > .submenu {
	overflow: visible !important;
}
.navigation .level0 .submenu > ul {
	margin-top: 11px;
}
.navigation .level0 .submenu > ul:before,
.navigation .level0 .submenu > ul:after {
	content: "";
	display: block;
	overflow: hidden;
	position: absolute;
}

.navigation {
	.level0 {
		.submenu {
			a {
				display: block;
				line-height: inherit;
				color: $theme-navigation-color;
				padding: 8px 0px;
			}
		}

		.level1 {
			a {
				padding: rem-calc(3px 0);
			}
			&.submenu {
				a {
					padding-left: rem-calc(20px);
					position: relative;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

.navigation .level0 .submenu a:hover,
.navigation .level0 .submenu a.ui-state-focus {
	background: transparent;
	color: $theme-navigation-color;
	text-decoration: none;
}
.navigation .level0 .submenu .submenu {
	top: 0 !important;
	left: 100% !important;
}
.navigation .level0 .submenu .submenu-reverse {
	left: auto !important;
	right: 100%;
}
.navigation .level0.more {
	position: relative;
	display: inline-block;
	text-decoration: none;
}
.navigation .level0.more:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 26px;
	line-height: inherit;
	color: inherit;
	content: "\e607";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.navigation .level0.more:before {
	display: none;
}
.navigation .level0.more:after {
	cursor: pointer;
	padding: 8px 12px;
	position: relative;
	z-index: 1;

	@include breakpoint(medium down) {
		cursor: default;
	}
}
.navigation .level0.more:hover > .submenu {
	overflow: visible !important;
}
.navigation .level0.more li {
	display: block;
}
