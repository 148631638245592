// ========== TYPOGRAPHY ==========
$theme-font-family:                     'Montserrat', 'Gotham', -apple-system, "Helvetica Neue", sans-serif;

// ========== COLOR ==========
$white:                                 #ffffff;

$theme-primary-color:                   #333333;
$theme-secondary-color:                 #D6083B;
$theme-tertiary-color:                  #512698;
$theme-accent-color:                    #D6083B;

$theme-primary-font-color:              #333333;
//$theme-secondary-font-color:            #bfbfbf;
$theme-tertiary-font-color:             $white;
$theme-accent-font-color:               $white;

$theme-header-background:               $white;
$theme-body-background:                 #f6f6f6;
$theme-footer-background:               #23173B;
$theme-footer-color:                    $white;
$theme-border-color:                    #D2D3D4;



// ========== CCOMPONENTS ==========

// + Buttons
// - Primary
$theme-button-style-1-background:       #D6083B;
$theme-button-style-1-color:            $white;
//$theme-button-style-1-border-color:     #ED1650;

// - Wishlist
$theme-button-style-2-background:       $white;
$theme-button-style-2-color:            #D6083B;
//$theme-button-style-2-border-color:     $white;

// - Outlined
$theme-button-style-3-background:       $white;
$theme-button-style-3-color:            #D6083B;
$theme-button-style-3-border-color:     #D6083B;
