.page-layout-2columns-left {

	.columns {
		@include flex-grid-row();

		@include breakpoint(large) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	main {
		position: relative;
	}

	&.catalogsearch-result-index,
	&.catalog-category-view {

		.sidebar {
			@include breakpoint(large) {
				@include flex-grid-column(25%);
			}
		}

		.main {
			@include breakpoint(large) {
				@include flex-grid-column(75%);
				padding-left: rem-calc(7px);
			}
			@include breakpoint(large down) {
				.product-item {

					&:hover {

						.product-item-details {
							opacity: 1;
							pointer-events: all;
						}

						.product-item-actions {
							opacity: 0;
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	.main {
		@include flex-grid-column(12);
		@include flex-order(2);

		@include breakpoint(large) {
			@include flex-grid-column(75%);
		}

		@include breakpoint(xlarge) {
			@include flex-grid-column(70%);
		}

		@include breakpoint(xxlarge) {
			@include flex-grid-column(9);
		}

		@include breakpoint(medium down) {
			padding: 0;
		}
	}

	.sidebar-main {
		@include flex-order(1);

		@include breakpoint(large) {
			@include flex-grid-column(25%);
		}

		@include breakpoint(xlarge) {
			@include flex-grid-column(30%);
		}

		@include breakpoint(xxlarge) {
			@include flex-grid-column(3);
		}

		@include breakpoint(medium down) {
			position: relative;
			top:rem-calc(0px);
			right: 0;
			transition: all 0.25s ease-in-out;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			pointer-events: auto;
			display: none;

			.filter-options, .account-nav {
				height: 0;
				opacity: 0;
			}

			.account-nav-content {

				.item.current {

					a, strong {
						pointer-events: none;
					}
				}
			}

			&.mobile-active {
				pointer-events: all;
				display: block;

				.filter-options, .account-nav {
					opacity: 1;
					height: auto;
				}

				.account-nav, .items  {
					@include vendor(box-shadow, none);
				}
			}
		}
	}

	.page-title-wrapper {
		@include flex-grid-row();

		.page-title {
			@include flex-grid-column(12,0);
			font-size: rem-calc(25);
			font-weight: $global-weight-semibold;
			letter-spacing: 1.07px;
			text-transform: none;

			@include breakpoint(large) {
				font-size: rem-calc(30);
				text-transform: initial;
			}

			@include breakpoint(medium down) {
				margin-bottom: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				font-size: rem-calc(20);

				&.has-menu-icon {

					&:after {
						@include flex-center;
						@include ird-icon($ird-icon-menu);
						color: $theme-primary-font-color;
						pointer-events: none;
						display: block;
						width: rem-calc(15);
						height: rem-calc(15);
					}
				}
			}

			@include breakpoint(small only) {
				font-size: rem-calc(18px);
			}

			&.has-menu-icon.mobile-active:after {

				@include breakpoint(medium down) {

					@include flex-center;
					@include ird-icon($ird-icon-close);
					color: $theme-primary-font-color;
					pointer-events: none;
					display: block;
					width: rem-calc(15);
					height: rem-calc(15);
				}
			}
		}
	}
}
