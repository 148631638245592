.btn-search {
    @include buttonStyle($theme-search-button-style);
    border-radius: 0 !important;
    font-size: rem-calc(15);
    text-transform: uppercase;
    margin-bottom: 0;
    padding: rem-calc(10 12);
    min-width: rem-calc(81px);

    @include breakpoint(small only) {
        width: 100%;
    }
}

.login-button {
    @include button($expand: true);
    background: $white;
    color: $theme-primary-font-color!important;
    text-transform: uppercase;
    @include breakpoint(medium down) {
        color: $theme-secondary-color !important;
    }
    @include breakpoint(retina) {
        color: $theme-secondary-color !important;
    }
}

.action-dismiss {
    @include buttonStyle(3);
    padding: rem-calc(6 0);
    width: rem-calc(150);
}

.action-accept {
    @include buttonStyle(1);
    padding: rem-calc(8 0);
    width: rem-calc(150);
}
