.delivery-country {
  @include flex;
  @include flex-align($y: middle);

  button:before {
    @include ird-icon($ird-icon-van);
    display: block;
    font-size: rem-calc(24px);
    margin-right: rem-calc(5px);
    @include breakpoint(large down) {
      margin-right: rem-calc(10px);
      margin-left: rem-calc(-32px);
    }

    color: $theme-primary-font-color;
  }

  button {
    font-size: 0;
  }
}
