// component variables

$filter-swatch-size: 24px;
$filter-swatch-distance:  8px;
$filter-item-padding: 12px;
$filter-remove-icon-size: 16px;

.filter {

	&-current {

		.items {
			@include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
			background: $white;
		}

		.item {
			background-color: $white;
			padding: rem-calc($filter-item-padding);
			position: relative;
			font-size: rem-calc(14px);
			line-height: rem-calc(14px);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: calc(100% - 16px);

			.remove {
				@include border-rounded;
				background-color: $theme-category-clear-item-background;
				color: $theme-category-clear-item-color;
				font-size: 0;
				height: rem-calc($filter-remove-icon-size);
				width: rem-calc($filter-remove-icon-size);
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0px;

				@include breakpoint(medium down) {
					right: rem-calc($filter-item-padding);
				}

				&:before, &:after {
					content: '';
					width: rem-calc(2px);
					height: rem-calc(10px);
					background: $theme-category-clear-item-color;
					position: absolute;
					top:50%;
					left: 50%;
					transform-origin: top left;
				}
				&:before {
					transform: rotate(45deg) translate(-50%, -50%);
				}

				&:after {
					transform: rotate(-45deg) translate(-50%, -50%);
				}
			}
		}

		.filter-value {
			font-weight: $global-weight-semibold;
		}

	}

	&-actions {

		@include flex;
		@include flex-align($x: right);
		margin-bottom: $global-margin;

		.filter-clear {

			@include border-rounded;
			background: $theme-category-clear-all-background;
			color: $theme-category-clear-all-color;
			font-weight: $global-weight-semibold;
			font-size: rem-calc(12);
			padding: rem-calc(5 10);

		}

	}

	&-options {

		.layered-navigation-block {
			@include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
			background: $white;
			margin-bottom: rem-calc(20px);

			.filter-options-title {
				padding: rem-calc($filter-item-padding);
				@include font(14, 14, 700, normal, uppercase);
			}
		}

		.items {
			margin: 0;
		}

		.swatch-attribute {
			padding: rem-calc($filter-item-padding);

			.swatch-attribute-options {
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				justify-content: flex-start;
				align-items: stretch;
				margin-bottom: rem-calc(-$filter-swatch-distance);

			}

			a {
				display: block;
				position: relative;
				margin-right: rem-calc($filter-swatch-distance)!important;
				margin-bottom: rem-calc($filter-swatch-distance)!important;
				min-height: rem-calc($filter-swatch-size);
				min-width: rem-calc($filter-swatch-size);

				&:last-child {
					margin-right: 0!important;
				}

				.swatch-option {
					@include font(12, 12, 500);
					margin: 0;
					background: $theme-body-background;
					color: $theme-primary-color;
					white-space: nowrap;
					padding: rem-calc(0 5px);
					height: 100%;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					top: 0;
					left: 0;
					min-width: rem-calc($filter-swatch-size);

					&:last-child {
						margin-right: 0!important;
					}

					.swatch-option {
						@include font(12, 12, 500);
						margin: 0;
						background: $theme-body-background;
						color: $theme-category-font-color;
						white-space: nowrap;
						padding: rem-calc(0 5px);
						height: 100%;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						top: 0;
						left: 0;
						min-width: rem-calc($filter-swatch-size);
					}
				}
			}
		}

		.item {

			a {
				color: $theme-category-font-color;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: rem-calc($filter-item-padding $filter-item-padding * 2 $filter-item-padding $filter-item-padding);
				@include font(14, 14, 400, normal, initial);
				position: relative;
				background-color: $white;

				&:hover {
					background: darken($theme-body-background, 5%);
				}

				&:after {
					@include ird-icon($ird-icon-chevron-right, 12px);
					@include flex-center;
					color: $theme-tertiary-color;
					width: rem-calc(20);
					height: rem-calc(20);
					position: absolute;
					right: rem-calc($filter-item-padding);
					top: 50%;
					transform: translateY(-50%);
				}

				.price {
					margin-right: rem-calc(2px);
				}
			}

			.filter-count-label {
				@include element-invisible;
			}

			.count {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: rem-calc(5px);
				font-size: rem-calc(14);
				color: rgba(75,79,84,0.75);

				&:before {
					content: "(";
				}

				&:after {
					content: ")";
				}
			}

			&.show-more-container {
				text-align: center;

				.show-more {
					&:after {
						@include ird-icon($ird-icon-chevron-down, 12px);
					}
				}
				.show-less {
					&:after {
						@include ird-icon($ird-icon-chevron-up, 12px);
					}
				}
				.show-more,
				.show-less {
					position: relative;
					right: rem-calc(11);
					cursor: default;
					@include font(14, 14, 400, normal, initial);
					&:after {
						justify-content: center;
						align-items: center;
						color: $theme-category-subcategory-chevron;
						width: rem-calc(22);
						height: rem-calc(17);
						position: absolute;
						top: 50%;
						transform: translateY(-30%);
					}
				}
			}
		}
	}
}
