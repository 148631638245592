.price-box {

	.price-container {
		margin: 0;

		.price-label {
			font-size: $pb-price-label-font-size;
		}

		.price-wrapper {

			.price {
				color: $theme-price-color;
				display: block;
				font-size: rem-calc($pb-price-font-size);
				font-weight: $pb-price-font-weight;
				line-height: rem-calc($pb-price-line-heihgt);
				text-align: center;
				width: 100%;
				white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -o-pre-wrap;
			}
		}
	}

	.old-price {
		margin-top: rem-calc(5);

		.price-wrapper {

			.price {
				font-size: rem-calc($pb-price-old-font-size);
				font-weight: $pb-old-normal-font-weight;
				line-height: rem-calc($pb-price-old-line-heihgt);
				margin-bottom: rem-calc(5);
				opacity: 0.75;
				text-decoration: line-through;
			}
		}
	}

	.special-price  {

		.price-container {

			.price {
				font-size: rem-calc($pb-price-special-font-size);
				font-weight: $pb-price-font-weight;
				line-height: rem-calc($pb-price-line-heihgt);
				text-align: center;
			}
		}
	}
	.normal-price {

		.price-container {
			align-items: center;
			display: flex;

			.price-label {
				display: inline-block;
				font-size: rem-calc($pb-price-normal-font-size);
				font-weight: $pb-old-normal-font-weight;
				line-height: rem-calc($pb-price-line-heihgt);
				margin-right: rem-calc(5);
				opacity: 1;
				text-transform: lowercase;
			}
		}
	}
}
.product-item-info {
	.price-box {
		.price-from {
			margin-bottom: 0;

			.price-container {
				justify-content: center;
				display: flex;

				.price-label {
					display: inline-block;
					font-size: rem-calc($pb-price-special-font-size);
					font-weight: $pb-old-normal-font-weight;
					line-height: rem-calc($pb-price-line-heihgt);
					margin-right: rem-calc(5);
					opacity: 0.5;
					text-transform: lowercase;
				}
			}

			.old-price{
				margin-bottom: 1rem;
				.price-label{
					font-size: 0;
				}
			}
		}
		.price-to{
			display: none;
		}
	}
}
