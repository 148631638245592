.block-search {
  position: relative;
  transition: all 0.3s ease;
  background: $white;
  height: rem-calc(67);
  width: 100%;
  max-width: rem-calc($global-width);
  margin: 0 auto;

  @include breakpoint(medium down) {
    overflow: hidden;
  }

  * {
    transition: all 0.25s ease-in-out;
  }

  .block-content {
    width: 100%;
  }

  .block-title {
    @include element-invisible;
  }

  .block-search-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    top:0;
    left: 0;
    background: $white;
    padding: rem-calc($global-margin);
    transition: all 0.25s ease;

    @include breakpoint(large) {
      .block-title {
        .close-button {
          display: none;
        }
      }
    }
  }

  .mobile-trigger-search {
    @include breakpoint(medium down) {
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      top:0;
      left: 0;
    }
  }

  &.is-mobile-active {

    .mobile-trigger-search {
      display: none;
    }

    .block-search-wrapper {
      @include breakpoint(medium down) {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        z-index: 10000;
      }

      @include breakpoint(small only) {
        .block-title,
        .block-content {
          max-width: rem-calc(360px);
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .block-title {

      @include breakpoint(medium down) {
        @include element-invisible-off;
        margin-bottom: rem-calc(30px);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
          margin: 0;
        }

        .close-button {
          position: relative;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

body.global-nav-active {
  .block-search {
    .block-title {
      @include breakpoint(large) {
        width: 20% !important;
        padding: 0;
        border: 0;
        float: left;
        @include element-invisible-off;

        h3 {
          line-height: 1;
        }
      }
    }
  }
}

.minisearch {
  display: flex;

  @include breakpoint(medium down) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .search {
    @include breakpoint(medium down) {
      width: 100%;
    }

    @include breakpoint(large) {
      flex-grow: 1;
      padding-right: rem-calc($global-margin);
    }

    input[type="text"] {

      background-color: $theme-search-bar-background;
      @include vendor(box-shadow, none);
      border: 0;
      height: rem-calc(35);
      margin-bottom: 0;
      padding-left: rem-calc(40);
      @include font(14, 17, $global-weight-skinny);
      width: 100%;

    }

    .control {
      position: relative;
      min-height: rem-calc(35px);
      background: $theme-body-background;
      @include border-rounded;
      @include breakpoint(medium down) {
        margin-bottom: rem-calc($global-margin * 2);
      }
    }

    .iredeem-icon {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width:rem-calc(40px);
      height: rem-calc(35px);
      pointer-events: none;
      justify-content: center;
      align-items: center;
    }

    .nested {
      @include element-invisible;
    }
  }

  .points-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(small only) {
      flex-wrap: wrap;
      margin-bottom: rem-calc(30px);
      width: 100%;

      .points-values {
        margin-bottom: rem-calc(30px);
        width: 100%;
      }

      .ranger-slider {
        margin-left: 0;
      }
    }

    @include breakpoint(medium only) {
      flex-grow: 1;
      padding-right: rem-calc($global-margin);

      .ranger-slider {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include breakpoint(large) {
      padding-right: rem-calc($global-margin);
    }
  }

  .actions {
    @include flex;
    @include flex-align($x: right);

    @include breakpoint(small only) {
      width: 100%;
    }

    @include breakpoint(medium only) {
      flex-grow: 1;

      .btn-search {
        width: 100%;
      }
    }
  }
}

.search-autocomplete {
  background: $white;
  border-radius: rem-calc(20);
  @include vendor(box-shadow, 0 0 8px 0 rgba(0, 0, 0, 0.14));
  z-index: 10000;
  padding: rem-calc(30);
  max-height: rem-calc(290px);
  overflow-y: auto;
  display: none!important;

  @include breakpoint(medium down) {
    left: 0;
    width: 100%!important;
  }

  &:empty {
    display: none;
  }

  &:hover, &:focus {
    display: none!important;
  }

  .suggested-title {
    @include flex;
    @include font(14, 17, $global-weight-semibold);
    color: $theme-search-bar-color;

  }

  ul {
    margin-top: rem-calc(15px);
    margin-bottom: rem-calc(0);

    li {

      @include font(15, 18, $global-weight-skinny);
      padding: rem-calc(10px 0);
      display: flex;

      span {
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        color: inherit;
      }

      .amount {
        display: none;
      }
    }

    li {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

input#search:focus + .search-autocomplete:not(:empty) {
  display: none!important;
}

input#search:focus:placeholder-shown + .search-autocomplete,
input#search:placeholder-shown + .search-autocomplete {
  display: none!important;
}

body:not(.is-reveal-open) .search-autocomplete {

  @include breakpoint(medium down) {
    display: none!important
  }
}


.field.search {

  .searchsuite-autocomplete {
    left: 0px;
    width: 100%;
    border-radius: rem-calc(20px);
    overflow: hidden;
    @include vendor(box-shadow, 0 3px 8px 0 rgba(0,0,0,0.14));
    padding: rem-calc(20px);
    border: none;
    margin-top: rem-calc(2px);

    @include breakpoint(medium down) {
      @include vendor(box-shadow, none);
      position: relative;
      border: 2px solid $theme-body-background;
    }

    .title {
      background: transparent;
      @include font(14, 14, $global-weight-semibold);
      @include flex;
      justify-content: space-between;
      color: $theme-primary-font-color;

      .see-all {
        @include font(14, 14, $global-weight-normal);
        color: $theme-secondary-color;
      }
    }

    .suggest,
    .product {

      ul {
        margin-left: rem-calc(-10px);
        margin-right: rem-calc(-10px);

        li {
          margin: 0;
          border: none;
          padding: rem-calc(5px 10px);
          margin-bottom: rem-calc(5px);
          &:last-child {
            margin-bottom: 0;
          }

          @include breakpoint(medium down) {
            margin-bottom: 0;
          }

          * {
            color: $theme-primary-font-color;
            @include font(14, 14, $global-weight-normal);
          }
        }
      }
    }

    .suggest {
      margin-bottom: rem-calc(10px);

      ul {

        li {

          margin-right: rem-calc(20px);

          a {
            display: block;
          }
        }
      }
    }

    .product {

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row-reverse;
        transition: all 0.15s ease;

        &:hover {
          background: $theme-body-background;
        }
      }

      .qs-option-image {
        width: rem-calc(38px);
        max-width: rem-calc(38px);
        flex-shrink: 0;
        background-color: $theme-body-background;

        a {
          display: block;

          img {
            width: 100%;
            min-height: rem-calc(38px);
            overflow: hidden;
            font-size: 0;
          }
        }
      }

      .qs-option-info {
        margin: 0;
        flex-grow: 1;
        padding-right: rem-calc(20px);
        padding-left: 0;
        width: calc(100% - 38px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .primary-search-options {
          @include flex;
          justify-content: space-between;
          align-items: center;
          max-width: 100%;
        }

        .qs-option-reviews {
          @include font(12, 17, $global-weight-skinny);
        }

        .qs-option-sku {
          @include font(12, 17, $global-weight-skinny);
        }

        .qs-option-shortdescription {
          @include font(12, 17, $global-weight-skinny);
        }

        .qs-option-description {
          @include font(12, 17, $global-weight-skinny);
        }

        .qs-option-addtocart {

          button {
            @include button-style-1;

            span {
              color: inherit;
            }
          }
        }
      }

      .qs-option-title  {
        flex-grow: 1;
        @include text-truncate;
        padding-right: rem-calc(20px);

        a {
          @include font(15, 38, $global-weight-normal);
          color: $theme-primary-font-color;
          display: block;
          @include text-truncate;
        }
      }

      .qs-option-price {
        @include font(15, 38, $global-weight-normal);
        color: $theme-primary-font-color;
        flex-shrink: 0;
        margin: 0;

        .price-box  {
          @include flex;
          align-items: center;

          .price-container .price-wrapper .price {
            @include font(15, 38, $global-weight-normal);
            color: $theme-primary-color;
          }

          .normal-price + .old-price {
            margin-left: rem-calc(20px);
          }

          .price-label {
            text-transform: lowercase;
          }
        }
      }
    }

    .no-result {
      @include font(15,15, $global-weight-normal);
      padding: rem-calc(10px);
      background: transparent;
      color: $theme-primary-font-color;
      text-align: left;
    }
  }
}

#search_mini_form {

  .btn-search {
    position: relative;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem-calc(70px);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;

      span {
        width: rem-calc(8px);
        height: rem-calc(8px);
        margin: rem-calc(3px);
        background-color: $white;
        border-radius: 100%;
        display: inline-block;
        animation: btn-search-spinner 1.2s infinite ease-in-out both;
        -webkit-animation: btn-search-spinner 1.2s infinite ease-in-out both;

        &.spinner-bounce-1 {
          animation-delay: -0.32s;
          -webkit-animation-delay: -0.32s;
          -moz-animation-delay: -0.32s;
          -o-animation-delay: -0.32s;
        }

        &.spinner-bounce-2 {
          animation-delay: -0.16s;
          -webkit-animation-delay: -0.16s;
          -moz-animation-delay: -0.16s;
          -o-animation-delay: -0.16s;
        }
      }
    }

  }

  &.submitted {

    .btn-search {

      pointer-events: none;
      cursor: default;

      span {
        font-size: 0;
      }

      .spinner {
        opacity: 1;
      }
    }
  }
}

// search button animation on submitting
@-webkit-keyframes btn-search-spinner {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }

  40% {
    -webkit-transform: scale(0.8)
  }
}

@keyframes btn-search-spinner {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
