.product-info-flexpay {
    border-bottom: $element-border;
    padding: 0 0 $global-margin 0;

    .flexpay {

        &-title {
            font-size: rem-calc(12);
            font-weight: $global-weight-skinny;   
            padding: rem-calc(15px 0); 
        }

        &-values {
            @include flex;
            @include flex-align($x: justify);
            font-size: rem-calc(15);
            width: rem-calc(313px);
            #points_value {
                color: $theme-price-color;
            }
        }

        &-slider {
            @include border-rounded;
            background-color: $theme-body-background;
            height: rem-calc(14);
            margin-bottom: rem-calc(20);
            width: rem-calc(313px);

            .slider {
                
                &-fill {
                    @include border-rounded;
                    height: rem-calc(14);
                    background-color: $theme-product-flexpay-slider;
                }

                &-handle {
                    @include border-rounded;
                    background-color: $theme-product-flexpay-slider;
                    border: 3px solid $white;
                    height: rem-calc(32);
                    width: rem-calc(32);
                }
            }
        }
    }
}

.catalog-product-view .tooltip.right:before {
    top:50%;
    transform: translateY(-50%);
}