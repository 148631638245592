.header-navigation-container {
	.navigation {
		> ul {
			.megamenu.level0:hover > a {
				// main menu items hover font color
				color: $theme-secondary-color !important;
			}
		}
	}
}

.navigation {
	> ul {
		position: static !important;

		.main-menu.mm-has-children {
			@include breakpoint(xlarge) {
				& a.level-top {
					& span:last-child {
						&:after {
							@include ird-icon($ird-icon-chevron-down, 9px);
							color: $theme-primary-color;
							margin-left: 8px;
							height: rem-calc(12);
							width: rem-calc(12);
							transition: all 0.15s ease;
							float: right;
							top: rem-calc(3px);
							position: relative;
							background: transparent;
						}
					}
				}
				&:hover {
					& a.level-top span:last-child:after {
						@include ird-icon($ird-icon-chevron-up, 9px);
						color: $theme-primary-color;
						margin-left: 8px;
						height: rem-calc(12);
						width: rem-calc(12);
						transition: all 0.15s ease;
					}
				}
			}
		}

		.megamenu {
			position: static;

			a.ui-state-active.level-top,
			a.ui-state-focus.level-top {
				//	nav menu hover background color
				background: $white;
			}
		}

		.megamenu.level0.has-active > .level-top,
		.megamenu.level0.active > .level-top {
			border-bottom: 0 none;
			color: $theme-primary-color;

			@include breakpoint(medium down) {
				color: $white;
			}
		}

		.level0 .submenu .submenu-reverse {
			left: 0 !important;
		}
		.level0 {
			margin-right: 0;
		}

		.megamenu.level0 .submenu .active > a,
		.megamenu.level0 .submenu .has-active > a {
			border-bottom: 0 none;
			color: $theme-primary-color;
		}
	}

	.megamenu {
		.level-top-sectioned {
			a.level-top {
				&:hover {
					border-bottom: 0;
					margin-bottom: 0;
				}
			}
		}

		&[class*="level-top-"] {
			.submenu {
				@include vendor(box-shadow, 0 20px 30px 0 rgba(0, 0, 0, 0.05));
				z-index: 5;

				.columns-group {
					padding: 0 0 20px;

					li {
						> a {
							span {
								@include font(15, 20, $global-weight-skinny);
							}
						}
					}
				}

				.level1 {
					display: inline-block;
					float: left;
					margin-bottom: 0;
					margin-top: 0;
					width: auto;

					.submenu {
						//submenu background color
						background: $white;

						li {
							cursor: pointer;
							margin: 0;
							width: 100%;
							@include breakpoint(large down) {
								> a {
									padding-left: rem-calc(25px);
								}
							}

							@include breakpoint(medium down) {
								cursor: default;
							}
						}
					}
				}

				li.level1 {
					margin-top: 0;

					> a {
						font-size: 1.65rem;
						// submenu heading color
						color: $theme-primary-color;

						span:last-child {
							border-bottom: 1px solid #e6e6e6;
							font-size: 15px;
							font-weight: 600;
							letter-spacing: 1.6px;
							padding: 0 0 5px;
							display: block;
							margin-bottom: rem-calc(5px);

							@include breakpoint(xlarge) {
								text-transform: capitalize;
							}
						}
					}
				}

				.level3 a span {
					text-transform: none;
				}

				&:not(:first-child) {
					.active {
						> a {
							border-left: 0 none;
						}
					}
				}
			}
		}

		&.level-top-default {
			.submenu {
				border: 0 none;
				@include vendor(box-shadow, 0 20px 30px 0 rgba(0, 0, 0, 0.05));

				li {
					margin-bottom: 5px;

					> a {
						background-color: transparent;
						padding-top: 8px;

						&:hover {
							background-color: transparent;
						}

						&:not(.parent) {
							padding-top: 8px !important;
							padding-bottom: 15px !important;
						}
					}

					&:first-child {
						padding-top: 15px;
						margin-bottom: 0 !important;
					}

					&:last-child {
						margin-bottom: 0;
						padding-bottom: 20px;
					}
				}
			}
		}

		&.level-top-fullwidth {
			position: unset;

			.level0.fullwidth {
				position: absolute;
				left: 0 !important;
				@include vendor(box-shadow, 0 0 0 0 transparent);

				@include breakpoint(xlarge) {
					top: 99% !important;
				}

				.fullwidth-wrapper {
					background-color: $white;
					// dropdown background
					border-top: 1px solid #ccc;
					padding-left: rem-calc(15px);
					padding-right: rem-calc(15px);
					position: relative;
					left: 0;
					@include vendor(box-shadow, 0 20px 30px 0 rgba(0, 0, 0, 0.05));

					.fullwidth-wrapper-inner {
						margin: 0 auto;

						.columns-group {
							padding-top: 10px;
							display: inline-block !important;
							vertical-align: top;
							width: auto !important;
						}
					}
				}
			}
		}

		&.level-top-sectioned {
			.sectioned {
				background-color: $white;
				// submenu background for page builder menu
				display: table;
				left: 0 !important;

				.columns-group {
					display: table-cell !important;
					float: none;
					left: 0 !important;
					overflow: hidden;
					padding-bottom: 25px;
					vertical-align: top;
					width: 100%;

					&:last-child > li:not(.parent) {
						padding-right: 20px;
					}

					li {
						> a {
							white-space: nowrap;
						}
					}
				}
			}
		}

		&.level-top-boxed {
			.submenu {
				.columns-group {
					margin-top: 0;
				}
			}

			.boxed {
				.submenu {
					.columns-group {
						right: inherit !important;
						width: 100% !important;
						padding-bottom: 0;

						> li {
							> a {
								white-space: nowrap;
							}
						}

						&:last-child {
							padding-bottom: 10px;
						}
					}

					.level1 {
						margin-top: 0;
					}
				}

				&.submenu {
					.columns-group {
						&:first-child {
							li:not(.parent) {
								margin-top: 0 !important;
							}
						}

						&:last-child {
							padding-bottom: 0 !important;
						}

						li {
							padding-left: 0;
						}
					}

					.level1 {
						.submenu {
							li {
								margin-top: 0 !important;
								padding-bottom: 0 !important;

								> a {
									&:first-child {
										margin-top: 0;
										padding-top: 12px;
										padding-bottom: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

$headerSubmenuBoxShadow: 1px 0px 0px 0px #ccc, -1px 0px 0px 0px #ccc;

header {
	.nav-sections {
		.navigation {
			.megamenu {
				&[class*="level-top-"] {

                    &.category-item.first.active.level-top.mm-first-item.mm-no-children.ui-menu-item a {
                        padding-left: rem-calc(15px);
                    }

					&.parent.mm-first-item.mm-has-children.ui-menu-item a {
						padding-left: rem-calc(15px);
					}
					.submenu {
						.columns-group {
							li {
								padding-right: rem-calc(35px);
							}
							li.level1 {
								a span.mm-subcategory-title {
									font-size: rem-calc(15px);
								}
							}
						}

						.level1 {
							.submenu {
								border: 0 none;
								@include vendor(box-shadow, inherit);
								display: block !important;
								position: relative;
								left: 0 !important;
								min-width: rem-calc(150px);
							}
						}
					}
				}

				a.level-top {
					padding: rem-calc(15px 15px);
					@include font(14, 17, $global-weight-semibold);
					position: relative;

					&:focus,
					&:hover {
						@include vendor(box-shadow, $headerSubmenuBoxShadow);
						margin-bottom: -1px;
						position: relative;
						z-index: 10;

						:after {
							content: "";
							display: block;
							width: 100%;
							height: rem-calc(3px);
							background: $theme-secondary-color;
							position: absolute;
							bottom: rem-calc(-2px);
							left: 0;
						}
					}
				}
			}
		}
	}
}
